<template>
  <div class="main_container">
    <div class="mx-auto mt-4">
      <div class="row mb-3 bread-crumbs">
        <div class="text-left col-12">
          <span>Rapports > Détails des engagements financiers</span>
        </div>
      </div>
      <div class="row justify-content-center my-4">
        <div class="col-8">
          <div class="card title-card">
            <div class="card-body text-center title">
              Détails des engagements financiers
            </div>
          </div>
        </div>
      </div>
      <!-- Vue-html2pdf -->
      <div class="row mt-4">
        <div class="col-md-2">
          <button class="btn btn-round btn-blue"
                  @click="generateReport()"> Imprimer </button>
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1200"
        filename="Rapport_Details_decomptes"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        v-if="loaded == true"
      >
        <!-- <section slot="pdf-content"> -->

        <div slot="pdf-content"  
             style="padding-left:90px;">
          <div class="row justify-content-center my-2">
            <div class="col-8">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Détails des engagements financiers
                </div>
              </div>
            </div>
          </div>
          <table class="table table-hover"> 
            <thead>
              <tr>
                <th class="th-blue"
                    scope="col">Banque
                </th>    
      
                <th class="th-blue"
                    scope="col">Pret mise en place
                </th>
                <th class="th-blue" 
                    scope="col" 
                    data-bs-toggle="tooltip"
                    data-bs-placement="left" 
                    title="Date de Mise en Place">
                  D.M.P.
                </th>
        
                <th class="th-blue"
                    scope="col" 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="right" 
                    title="Date de Fin de Remboursement">DFR</th>
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Montant Remboursés en principal"
                    v-if="(year.getMonth()+1)<10">M.R.P au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Montant Remboursés en Principal"
                    v-else>M.R.P au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
      
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="right" 
                    title="Montant Remboursés en Interet"
                    v-if="(year.getMonth()+1)<10">M.R.I au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="right" 
                    title="Montant Remboursés en Interet"
                    v-else>M.R.I au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
      
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="right" 
                    title="Total Remboursement"
                    v-if="(year.getMonth()+1)<10">T.R au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="right" 
                    title="Total Remboursement"
                    v-else>T.R au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
        
                <th class="th-blue"
                    scope="col"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="left" 
                    title="Reste à Payer"
                    v-if="(year.getMonth()+1)<10">R.P au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
                <th class="th-blue"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="left" 
                    title="Reste à Payer"
                    scope="col"
                    v-else>R.P au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
              </tr>
            </thead>
            <tbody v-if="loaded == false">
              <tr scope="row" 
                  class="table-secondary">
                <td colspan="8">Chargement En cours...</td>  
              </tr>
            </tbody>
            <tbody v-else-if="engagement.length > 0">
              <tr scope="row"
                  v-for="(finance,ekey) in engagement"
                  :key="ekey">
                <td>{{finance.banque}}</td>
                <td>{{finance.pret_mise_en_place}}</td>
                <td>{{finance.date_debut}}</td>
                <td>{{finance.date_fin}}</td>
                <td>{{Math.round(finance.montant_rembourse_principal).toLocaleString()}}</td>
                <td>{{Math.round(finance.montant_rembourse_interet).toLocaleString()}}</td>
                <td>{{Math.round(finance.total_remboursement).toLocaleString()}}</td>
                <td>{{Math.round(finance.reste_a_payer).toLocaleString()}}</td>
              </tr>
            </tbody>
            <!-- <tfoot>
                <tr scope="row" 
                    class="bg-info">
                  <td colspan="4">TOTAL</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tfoot> -->
          </table>
          
          <div class="row">
            <div style="width: 700px; margin: auto">
              <BarChart :chartdata="randomChart1" 
                        :options="optionsChart1" />
            </div>
          </div>
        </div>
        <!-- </section> -->
      </vue-html2pdf>
      <!-- END of Vue-html2pdf  -->
      <table class="table table-hover table-striped text-center mx-auto mt-2"> 
        <thead>
          <tr>
            <th class="th-blue"
                scope="col">Banque
            </th>    
  
            <th class="th-blue"
                scope="col">Pret mise en place
            </th>
  
            <th class="th-blue" 
                scope="col" 
                data-bs-toggle="tooltip"
                data-bs-placement="left" 
                title="Date de Mise en Place">
              D.M.P.
            </th>
  
            <th class="th-blue"
                scope="col" 
                data-bs-toggle="tooltip" 
                data-bs-placement="right" 
                title="Date de Fin de Remboursement">DFR</th>
  
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="Montant Remboursés en principal"
                v-if="(year.getMonth()+1)<10">M.R.P au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="Montant Remboursés en Principal"
                v-else>M.R.P au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
  
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="right" 
                title="Montant Remboursés en Interet"
                v-if="(year.getMonth()+1)<10">M.R.I au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="right" 
                title="Montant Remboursés en Interet"
                v-else>M.R.I au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
  
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="right" 
                title="Total Remboursement"
                v-if="(year.getMonth()+1)<10">T.R au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="right" 
                title="Total Remboursement"
                v-else>T.R au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
  
            <th class="th-blue"
                scope="col"
                data-bs-toggle="tooltip" 
                data-bs-placement="left" 
                title="Reste à Payer"
                v-if="(year.getMonth()+1)<10">R.P au {{year.getDate()}}/0{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
            <th class="th-blue"
                data-bs-toggle="tooltip" 
                data-bs-placement="left" 
                title="Reste à Payer"
                scope="col"
                v-else>R.P au {{year.getDate()}}/{{year.getMonth()+1}}/{{year.getFullYear()}}</th>
          </tr>
        </thead>
        <tbody v-if="loaded == false">
          <tr scope="row" 
              class="table-secondary">
            <td colspan="8">Chargement En cours...</td>  
          </tr>
        </tbody>
        <tbody v-else-if="engagement.length > 0">
          <tr scope="row"
              v-for="(finance,ekey) in engagement"
              :key="ekey">
            <td>{{finance.banque}}</td>
            <td>{{finance.pret_mise_en_place}}</td>
            <td>{{finance.date_debut}}</td>
            <td>{{finance.date_fin}}</td>
            <td>{{Math.round(finance.montant_rembourse_principal).toLocaleString()}}</td>
            <td>{{Math.round(finance.montant_rembourse_interet).toLocaleString()}}</td>
            <td>{{Math.round(finance.total_remboursement).toLocaleString()}}</td>
            <td>{{Math.round(finance.reste_a_payer).toLocaleString()}}</td>
          </tr>
        </tbody>
        <!-- <tfoot>
          <tr scope="row" 
              class="bg-info">
            <td colspan="4">TOTAL</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </tfoot> -->
      </table>

    </div>
  </div>
</template>
<style>
  .table-hover{
    font-size:0.7em;
  }
</style>
  
<script>
import { Tooltip } from "bootstrap"
import { mapActions, mapGetters } from "vuex"
import VueHtml2pdf from "vue-html2pdf"
export default {
  name: "EngagementFinanciere",
  components: {
    VueHtml2pdf,
  },
  data: () => ({
    year: new Date(),
    loaded: false,
    engagement: "",
  }),
  created() {
    this.getEngagementFinancier()
  },
  watch: {
    engagementFinancier() {
      if (this.engagementFinancier) {
        this.loaded = true
        console.log(this.engagementFinancier.engagement_financier)
        this.engagement = this.engagementFinancier.engagement_financier
      }
    },
  },
  computed: {
    ...mapGetters(["engagementFinancier"]),
  },
  methods: {
    ...mapActions(["getEngagementFinancier"]),
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
  },

  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
}
</script>